
require("./../../node_modules/@module-federation/nextjs-mf/src/include-defaults.js");
import dynamic from 'next/dynamic';
import { useEffect } from 'react';
import create from 'zustand';
import SEO from 'global/SEO';
import usePublicProtectedRoute from 'hooks/usePublicProtectedRoute';
import tradingStore from 'lib/stores/trading';
import mainLayoutStore from 'global/MainLayout/store';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

const captchaSiteKey = process.env.NEXT_PUBLIC_GOOGLE_RECAPTCHA_V3;
const AuthLayout = dynamic(() => import('global/AuthLayout'));
const Login = dynamic(() => import('features/login'));

const useTradingStore = create(tradingStore);
const useMainLayoutStore = create(mainLayoutStore);

function LoginPage() {
  usePublicProtectedRoute();

  const { errorCodeSecurities, resetErrorCodeSecurities } = useTradingStore(
    (s) => ({
      errorCodeSecurities: s.errorCode,

      resetErrorCodeSecurities: s.resetErrorCode,
    }),
  );
  const { errorCodeSocial, resetErrorCodeSocial } = useMainLayoutStore((s) => ({
    errorCodeSocial: s.errorCode,

    resetErrorCodeSocial: s.resetErrorCode,
  }));

  useEffect(() => {
    resetErrorCodeSocial();
    resetErrorCodeSecurities();
  }, [errorCodeSecurities, errorCodeSocial]);

  return (
    <AuthLayout>
      <SEO description="Login ke aplikasi Stockbit. Bisa dengan Google, Facebook atau Email." />
      <GoogleReCaptchaProvider reCaptchaKey={captchaSiteKey}>
        <Login />
      </GoogleReCaptchaProvider>
    </AuthLayout>
  );
}

export default LoginPage;
